import { request } from "./service"
import store from "@/store/index"
import { AxiosResponse, AxiosRequestConfig } from "axios"

const service = request("/api")

service.interceptors.request.use(
  function(config: AxiosRequestConfig) {
    if ((store.state as any).app.address) {
      const { address, deviceId } = (store.state as any).app
      config.headers["Auth-Addr"] = address
      config.headers["Auth-IMEI"] = deviceId
      console.log("Auth-IMEI", deviceId)
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

// service.interceptors.response.use(function (res: AxiosResponse) {
//   // 除了code: 200, 其余均在 catch 状态
//   return res
// })

/**
 * 邀请用户
 * @param invitation
 * @returns
 */
export const invitePool = function(invitation: string) {
  return service.post("/web/pool-invite", { invitation })
}

/**
 * 用户信息
 * @returns
 */
export const getUserInfo = function() {
  return service.post("/web/user-info", {})
}

/**
 * 绑定委托
 * @param id
 * @param amount
 * @param isBind
 * @returns
 */
export const bindPool = function(
  id: string,
  amount: string | number,
  isBind?: boolean
) {
  return service.post("/web/bind-pool", { id, amount, isBind })
}

/**
 * 解绑票池
 * @param id
 * @returns
 */
export const unbindPool = (id: string) => {
  return service.post("/web/unbind-pool", { id })
}
/**
 * 真正解绑票池
 * @param id
 * @returns
 */
export const delPool = (id: string) => {
  return service.post("/web/del-pooladdr", { id })
}
/**
 * 是否是超级节点|社区节点
 * @param addr
 * @returns
 */
export const getTop = function(addr: string) {
  return service.get("/web/is-top", { params: { addr } })
}

/**
 * 是否初次绑定委托
 * @param id
 * @returns
 */
export const checkBind = function(id: string) {
  return service.get("/web/check-bind", { params: { id } })
}

/**
 * 用户是否绑定投票
 * @param id
 * @returns
 */
export const isBind = function(addr: string) {
  return service.get("/web/is-bind", { params: { addr } })
}

/**
 * 票池信息
 * @param id
 * @returns
 */
export const poolInfo = function(id: string | number) {
  return service.get("/web/pool-info", { params: { id } })
}

/**
 * 票池市场
 * @param data
 * @returns
 */
export const poolList = function(data: {
  page: number
  pageSize: number
  limit?: number
}) {
  return service.get("/web/pool-list", { params: data })
}

/**
 * 票池列表
 * @param data
 * @returns
 */
export const userMachineList = function(data: {
  // id?: string|number,
  // addr?: string,
  page: string | number
  pageSize: string | number
  status?: string | number
}) {
  return service.get("/web/user-machine-list", { params: data })
}

export const userMinedData = function() {
  return service.get("/web/user-mined-data", {})
}

export const userMinedDetail = function(id: string | number) {
  return service.get("/web/user-mined-detail", { params: { id } })
}

export const userMinedList = function(data: {
  id?: string | number
  addr?: string
  page: string | number
  pageSize: string | number
  limit?: string | number
}) {
  return service.get("/web/user-mined-list", { params: data })
}

export const ticketClose = (params: { addr: string; miner: string }) => {
  return service.post("/web/ticket-close", params)
}

/**
 * 获取推荐信息及列表
 */
export const getInviteList = (params: {
  page: number
  pageSize: number
}): Promise<{
  data: {
    data: {
      list: { id: string; addr: string; tickets: number; bindAt: string }[]
      count: string
      tickets: number
    }
  }
}> => {
  return service.get("/web/user-invite-list", { params })
}

/**
 * 继续挖矿
 */
export const continueMining = (params: {
  addr: string
}): Promise<{ data: { data: { isSucc: boolean } } }> => {
  return service.post("/web/auto-next-mine", params)
}
