// 游戏节点
// export const gameNodeURL = 'http://47.92.120.76:8903';
export const gameNodeURL = 'http://192.168.100.139:8801';
// 合约名称
export const contractName = 'user.p.fzmtest.powerball';
// 充币地址
export const rechargeAddr = '19P8bhxiBkBVbVojgYRoQcWVWXMtksf76q';
// 提币地址
export const withdrawalAddr = '1DyJrTktyFxBjzJLg5oPpCPH4RZZiaJQJL';
//  游戏id
export const gameLotteryId = '0x0cace9c04e0e044dd630dd88d2a20243360bc1a6413a5ce4015d07099387c1ac';
//  冲提现地址
export const rechargeWithdrawUrl = 'http://47.74.190.154/gameCashier';


//树莓派设备外网统计服务
export const paiOuterNetURL = process.env.VUE_APP_SERVER

export const paiDeviceManager = process.env.VUE_APP_DEVICEMANAGER

export const airDropURL = process.env.VUE_APP_AIRDROP


export const CONTRAC_ADDRESS = '16htvcBNSEA7fZhAdLJphDwQRQJaHpyHTp' // 合约地址
export const APP_NAME = 'YUAN-miner' // 应用名称
export const TICKET_PRICE = 3000 // 3000bty
export const withhold = -1   // 交易组是否代扣  代扣：1  不代扣：-1
