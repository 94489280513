import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import "vant/lib/index.css"
import "@/style.css"

import vconsole from "vconsole"

// if (process.env.VUE_APP_ENV === "dev" || process.env.VUE_APP_ENV === "test") {
new vconsole()
// }

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
