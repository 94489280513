import Vue from 'vue'
import Vuex from 'vuex'
import { appStore } from './app'
import { global } from './global'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app: appStore,
    global: global
  }
})
