
const stateData = {
	showModal: true,
  inputBalance: 0
}
export type AppType = typeof stateData
export const global = {
	namespaced: true,
		state: () => ({
			...stateData
		}),
	mutations: {
		SET_SHOWMODAL(state: AppType, s: boolean) {
			state.showModal = s
		},
    SET_BALANCE(state: AppType, c: number) {
      state.inputBalance = c
    }
	},
	actions: {

	},
	getters: {
			
	}
};
  
