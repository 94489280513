
const stateData = {
	address: '',   // 用户地址
  deviceId: ''    // 设备ID
}
export type AppType = typeof stateData
export const appStore = {
	namespaced: true,
		state: () => ({
			...stateData
		}),
	mutations: {
		SET_ADDRESS(state: AppType, s: string) {
			state.address = s
		},
		CLEAR_ADDRESS(state: AppType) {
			state.address = ''
		},
		SET_DEVICEID(state: AppType, s: string) {
			state.deviceId = s
		},
		CLEAR_DEVICEID(state: AppType) {
			state.deviceId = ''
		},
	},
	actions: {

	},
	getters: {
			
	}
};
  
