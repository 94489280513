import "whatwg-fetch";

interface ResponseError extends Error {
  response: any;
}

export default class HttpProvider {
  private errorHandler: any;
  private url: any;

  constructor(url: string, errorHandler: any = null) {
    this.errorHandler = errorHandler;
    this.url = url;
    this.setUrl.bind(this);
    this.doFetch.bind(this);
  }

  public setUrl(url: string) {
    this.url = url;
  }

  public doFetch(config: any) {
    const { httpHeaders = {}, postdata } = config;
    let { url } = config;

    if (!url) {
      url = this.url;
    }

    const headers = {
      // 'Content-Type': 'text/plain',
      "Content-Type": "application/json",
      ...httpHeaders,
    };

    const reqConfig: RequestInit = {
      method: "POST",
      headers,
      redirect: "follow",
    };

    if (postdata) {
      reqConfig.body = JSON.stringify(postdata);
    }

    return fetch(url, reqConfig)
      .then(this.checkStatus)
      .then((resp) => resp.json())
      .catch((e) => {
        if (this.errorHandler) {
          this.errorHandler(e);
        } else {
          throw e;
        }
      });
  }

  private checkStatus(response: any) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error: ResponseError = new Error(
        response.statusText
      ) as ResponseError;
      error.response = response;
      throw response.statusText;
      // throw error;
    }
  }
}
