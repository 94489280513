import { request } from "./service";
import axios from "axios";
import { sign, signGroup } from "@/utils/bridge";
import { rawTransaction } from "@/type/request";
// import { test } from '@/utils/test'

const url =
  process.env.VUE_APP_ENV === "prod" ? "/chain" : process.env.VUE_APP_CHAIN;
// const url = process.env.VUE_APP_CHAIN;
console.log("process env", process.env.VUE_APP_ENV, process.env.VUE_APP_CHAIN);

const service = request(`${url}`);

async function chainRequire(
  method: string,
  data: any[],
  b?: boolean,
  _this?: any,
  cb?: any
) {
  const CancelToken = axios.CancelToken;
  return await service
    .request({
      data: {
        jsonrpc: "2.0",
        id: 1,
        method: method,
        params: data,
      },
      method: "POST",
      cancelToken: b
        ? new CancelToken(function executor(c) {
            _this.cancelAjax = c;
          })
        : undefined,
    })
    .then((res) => {
      if (cb) {
        return cb(res);
      }
      return res;
    })
    .catch((err) => {
      if (cb) {
        return cb(err);
      }
      return err;
    });
}

/**
 * 签名
 * @param txHex
 * @param fn
 */
function signTransition(txHex: string, fn?: any) {
  sign(
    {
      createHash: txHex,
      exer: "ticket",
      isWithhold: -1,
    },
    fn
  );
}

/**多笔签名 */
function signGroupTransition(txHex: string, fn?: any) {
  signGroup(
    {
      createHash: txHex,
      exer: "ticket",
      withhold: 1,
    },
    fn
  );
}
// async function signTransition(txHex:string,privateKey?:string){
//   return await chainRequire('Chain33.SignRawTx',[{
//       privkey: privateKey,
//       txHex: txHex,
//       expire: '1h',
//   }])
// }

/**
 * 发送签名
 * @param txHex
 * @returns
 */
async function sendTransaction(txHex: string) {
  return await chainRequire("Chain33.SendTransaction", [
    {
      data: txHex,
    },
  ]);
}

/**
 * 获取交易状态
 */
async function getTransaction(hash: string) {
  return await chainRequire("Chain33.QueryTransaction", [
    {
      hash: hash,
    },
  ]);
}

export default {
  /**
   * 构造交易
   * @param data
   * @returns
   */
  createTransaction: async function(
    data: rawTransaction,
    fn?: any,
    fail?: any
  ) {
    return await chainRequire("Chain33.CreateRawTransaction", [data]);
  },
  /**
   * 获取合约余额
   * @param addr
   * @returns
   */
  getBalance: async function(addr: string, execer: string) {
    return service.request({
      data: {
        id: 1,
        method: "Chain33.GetBalance",
        params: [
          {
            addresses: [addr],
            execer: execer,
          },
        ],
      },
      method: "POST",
    });
  },
  /**
   *
   * @param addr
   * @param execer
   * @returns
   */
  getAllBalance: async function(addr: string) {
    return service.request({
      data: {
        id: 1,
        method: "Chain33.GetAllExecBalance",
        params: [
          {
            addr,
          },
        ],
      },
      method: "POST",
    });
  },

  getProperFee: async function(txCount: number, txSize: number) {
    return await chainRequire("Chain33.GetProperFee", [
      {
        txCount,
        txSize,
      },
    ]);
  },

  signTransition,

  signGroupTransition,

  sendTransaction,

  getTransaction,

  /**
   * 投票绑定
   * @param data
   * @returns
   */
  createBindMiner: async function(data: {
    bindAddr: string;
    originAddr: string;
    amount: number;
    checkBalance: boolean;
  }) {
    const res = await chainRequire("ticket.CreateBindMiner", [data]);
    return res;
  },

  createRawTxGroup: function(data: { txs: any }[]) {
    console.log(1111111111111111111, data);
    return chainRequire("Chain33.CreateRawTxGroup", data);
  },
  /**
   * 获取绑定的投票地址
   * @param addr
   * @returns
   */
  getTicketAddress: function(addr: string) {
    return service.request({
      data: {
        method: "Chain33.Query",
        params: [
          {
            execer: "ticket",
            funcName: "MinerAddress",
            payload: {
              data: addr,
            },
          },
        ],
      },
      method: "POST",
    });
  },

  /**
   * 获取票工地址的对应的冷钱包地址
   * @param addr
   * @returns
   */
  getWalletAddress: function(addr: string) {
    return service.request({
      data: {
        method: "Chain33.Query",
        params: [
          {
            execer: "ticket",
            funcName: "MinerSourceList",
            payload: {
              data: addr,
            },
          },
        ],
      },
      method: "POST",
    });
  },

  closeTicket: function(addr: string) {
    return service.request({
      data: {
        method: "ticket.CloseTickets",
        params: [
          {
            minerAddress: addr,
          },
        ],
      },
      method: "POST",
    });
  },

  /** */
};
