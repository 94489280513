import axios from "axios";
import HttpProvider from "@/utils/httpProvider";
import {gameNodeURL} from '@/constance/config';

// const baseUrl = '/api'

export const request = function(baseUrl: string) {
  return axios.create({
    baseURL: baseUrl,
    timeout: 15000
  })
}


export const httpProvider: HttpProvider = new HttpProvider(gameNodeURL);
export function callDeviceManagerAPI(params:any = {},apiName='',url=''){
  return httpProvider.doFetch({
    url:`http://${url}:8803/pai/${apiName}`,
    postdata: { 
      ...params
    }
  }).then((res: any) => {
    console.log('矿机接口->',res)
    if(res.code === 200){
      return res.data;
    }else if(res.code === 400){
      return Promise.reject('请求参数错误')
    }else if (res.code === 5000) {
      return Promise.reject('安全关闭节点，请重启矿机\n其他操作导致请咨询客服')
    } else if (res.code === 5001) {
      return Promise.reject('安全关闭节点，请重启矿机\n其他操作导致请咨询客服') // 树莓派上出错了，可能缺少某些文件之类的
    } else if (res.code === 5002) {
      return Promise.reject('正在更新')
    } else if (res.code === 5004) {
      return Promise.reject('网络错误,  请检查您的网络')
    } else if(res.code === 5013){
      return Promise.reject(res.data);
    }else if(res.code === 5014){
      return Promise.reject('未解决');
    } else if(res.code === 5015){
      return Promise.reject(res.msg);
    }else{
      console.log('矿机错误',res);
      throw '矿机错误';
    }
  });
}

