import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home/index.vue"),
  },
  {
    path: "/details",
    name: "Details",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Details/index.vue"),
    // component: () => import(/* webpackChunkName: "about" */ '../views/Details.vue')
  },
  {
    path: "/mine",
    name: "Mine",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Mine/index.vue"),
  },
  {
    path: "/record",
    name: "record",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Record/index.vue"),
  },
  {
    path: "/recommend",
    name: "recommend",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Recommend/index.vue"),
  },
  {
    path: "/noPage",
    name: "noPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NoPage/index.vue"),
  },
  {
    path: "/nodePage",
    name: "nodePage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/NodePage/List/index.vue"
      ),
  },
  {
    path: "/nodeDetail",
    name: "miningDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/NodePage/Details/index.vue"
      ),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
