const jsBridge = (window as any).jsBridge
const _jsBridge = new jsBridge()

export const sign = (params: any, fn?: Function) => _jsBridge.sign(params, fn)
export const getCurrentBTYAddress = (params: any, fn?: Function) => _jsBridge.getCurrentBTYAddress(params, fn)
export const signGroup = (params: any, fn?: Function) => _jsBridge.signTxGroup(params, fn)
export const closeWebview = () => _jsBridge.closeCurrentWebview()
export const getDeviceId = (params: any, fn?: Function) => _jsBridge.getDeviceId(params, fn)
export const getScan = (params: any, fn?: Function) => _jsBridge.scanQRCode(params, fn)
export const getDeviceList = (params: any, fn?: Function) => _jsBridge.getDeviceList (params, fn)
export const getWifiName = (params: any, fn?: Function) => _jsBridge.getCurrentWifi (params, fn)
export const importSeed = (params: any, fn?: Function) => _jsBridge.importSeed (params, fn)