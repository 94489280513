






// 优化进入的请求逻辑，promise.all, 失败刷新
import Vue from "vue"
import { isBind } from "@/service/pool"
import service from "@/service/chain33"
import { getDeviceId, getCurrentBTYAddress } from "@/utils/bridge" // 币钱包环境
import { test } from "@/utils/test" // 本地测试
import { Toast } from "vant"
import { getUserInfo } from "@/service/pool"
export default Vue.extend({
  methods: {
    // 账号是否能进入投票，账户可多次切换，刷新时判断是否需重定向回首页
    async hasPoolAddr(addr: string) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        className: "toast_x",
      })
      try {
        const response = await service.getTicketAddress(addr)
        const walletRes = await service.getWalletAddress(addr)
        const _result = response.data.result?.data
        Toast.clear()
        if (walletRes.data.result?.datas) {
          // 查询到存在冷钱包，跳转空白页(即存在挖矿用户，为票池地址)
          this.$router.replace({
            path: "/noPage",
            query: { type: "3", status: "1" },
          })
        }
        // 若存在地址，判断此地址是否属于当前项目，catch 表明不是当前项目用户
        if (_result) {
          isBind(_result)
            .catch((res: any) => {
              const reason = res.response.data.reason
              if (reason) {
                this.$router.replace({ path: "/noPage", query: { type: "3" } })
              } else {
                // 多账户切换时重定向
                this.$router.replace({ path: "/" })
              }
            })
            .then(() => {
              // 多账户切换时重定向
              this.$router.replace({ path: "/" })
            })
        }
        if (!walletRes.data.result?.datas && !_result) {
          this.getInfo()
        }
      } catch (e) {
        Toast({
          message: "请求超时,请稍后再试",
          className: "toast_x",
        })
      }
    },
    // 获取用户类型，type -> 1: 社区阶段 2：超级节点 status -> 1：票池地址
    getInfo() {
      getUserInfo().then((res) => {
        const type = res.data.data.type + ""
        const status = res.data.data.status + ""
        if (type === "1" || type === "2") {
          this.$router.replace({ path: "/noPage", query: { type: type } })
        } else if (status === "1") {
          this.$router.replace({ path: "/noPage", query: { status } })
        } else {
          // 多账户切换时重定向
          this.$router.replace({ path: "/" })
        }
      })
    },
  },
  mounted() {
    getCurrentBTYAddress("", (res: string) => {
      console.log("BTY ADDRESS", res)
      this.$store.commit("app/SET_ADDRESS", res)
      getDeviceId("", (ret: string) => {
        console.log(ret, "DEVICEID")
        this.$store.commit("app/SET_DEVICEID", ret)
        this.hasPoolAddr(res)
      })
    })
    // this.$store.commit("app/SET_ADDRESS", test.addr)
    // this.$store.commit("app/SET_DEVICEID", test.deviceId)
    // this.hasPoolAddr(test.addr)
  },
})
